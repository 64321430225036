<template>
  <div class="home">
    <headerNav></headerNav>
    <div class="header">
      <div class="header-main flex-i">
        <img class="header-logo" src="../../assets/images/login/logo.png" alt="">
        <span class="header-title">榆阳产业服务平台营销商城</span>
        <div class="header-search-box flex-s-b-i">
          <input class="header-search" v-model="searchContent" type="text">
          <button class="sch_button" @click="goList">搜索</button>
          <!-- <div class="header-search-btn">搜索</div> -->
        </div>
      </div>
      <!-- <img src="" alt="">
      <span>榆阳产业服务平台商城</span> -->
    </div>
    <!-- <img style="margin-top: 20px" src="../../assets/images/index.png" alt=""> -->


    <!-- 轮播区域 -->
    <div class="lunbo_big">
      <div class="lunbo">
        <div class="lunbo_left" >
          <ul class="one" style="cursor: pointer;">
            <li class="oneli" v-for="(item,index) in catalogList" :key="index" @mouseenter="showCatalog(index)" @mouseleave="hideCatalog">{{item.catalogName}} <span style="float: right; line-height: 50px" class="el-icon-arrow-right"></span>
              <ul class="two" v-if="lmIndex == index">
                <li v-for="(it,inx) in item.children" :key="inx" style="cursor: pointer;"><div>
                    <div style="font-weight: bolder;cursor: pointer;" >{{it.catalogName}}<span class="el-icon-arrow-right"></span></div>
                    <ul class="three">
                      <li v-for="(i,ix) in it.children" style="cursor: pointer;" :key="ix" @click="goShopList(i)">{{i.catalogName}}</li>
                  
                    </ul>
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </div><div class="lunbo_center">
          <el-carousel height="500" style="width: 100%">
            <el-carousel-item>
              <img src="../../assets/images/lunbo1.png" />
            </el-carousel-item>
            <el-carousel-item>
              <img src="../../assets/images/lunbo2.png" />
            </el-carousel-item>
           
          </el-carousel>
        </div><div class="lunbo_right">
            <div style="cursor: pointer;" @click="goShopListRight('羊肉')">
              <img src="../../assets/images/guanggao1.png" alt="">
            </div>
            <div @click="goShopListRight('定制')" style="margin: 10px 0; cursor: pointer;">
              <img src="../../assets/images/guanggao2.png" alt="">
            </div>
            <div style="cursor: pointer;" @click="goShopListRight('直采')">
              <img src="../../assets/images/guanggao3.png" alt="">
            </div>
        </div>
      </div>
    </div>

    <!-- 热销产品 -->
    <div class="hot">
      <span></span><h3>热销商品</h3><span></span>
    </div>
    <div class="hotgoods_big">
      <div class="hotgoods">
        <div v-for="(item,index) in hotGoodsList" :key="index" @click="goDetail(item)">
          <img :src="item.uccCommodityPicRspBOList[0].commodityPicUrl" alt="">
        </div>

      </div>
    </div>

    <!-- 猜你喜欢 -->
    <div class="hot" style="margin: 20px 0">
      <span></span><h3>猜你喜欢</h3><span></span>
    </div>

    <div class="guess_like">
      <ul class="guess_like_title">
        <li v-for="(item,index) in catalogList" :key="index" :class="isCatalog == item.guideCatalogId ? 'show_border' : ''" v-if=" index < 6" @click="chooseCatalog(item)">{{item.catalogName}} <span></span></li>
      </ul>

      <ul class="guess_goods">
        <li v-for="(item,index) in goodsList" :key="index" @click="goDetail(item)">
          <img :src="item.uccCommodityPicRspBOList[0].commodityPicUrl" alt="">
          <p class="by2">{{item.commodityName}}</p>
          <div class="flex-s-b">
            <span class="price">￥{{item.minPrice/10000}}</span>
            <div style="width: 40%; text-align: right; margin-right: 5px;" class="by1">{{item.shopName}}</div>
          <!-- <p><span class="sheng">广西</span><span class="shi">桂林</span></p> -->
          </div>
        </li>
      </ul>

      <div class="gobottom">到底了呢～再看看别的～</div>
    </div>

    <div class="bottom">
        <p class="aboutus"><span>关于我们</span><span></span><span>联系我们</span></p>
        <p class="codeJ">京ICP备19014699号-2</p>
        <p class="banquan">Copyright©2004-2021  蜂窝FW.com 版权所有</p>
    </div>

    <div style="height: 30px; background: rgba(132, 199, 84, 1)">

    </div>

  </div>
</template>

<script>
import headerNav from './../../components/headerNav'
import '../../assets/css/home.scss'
// @ is an alias to /src
export default {
  name: 'home',
  data(){
      return{
        searchContent: '',
        lmIndex: -1,
        goodsList: [],
        isCatalog: -1,
        catalogList: [],
        selectData: [],
        hotGoodsList: []
      }
  },
  components: {
    headerNav
  },
  mounted(){
    this.qryDic();
    
  },
  methods:{
    showCatalog(index){
      this.lmIndex = index;
      // this.tag = 
    },
    goShopList(data){
      console.log(data.catalogName)
      this.$router.push({
        name: 'goodsList',
        query: {
          id: data.guideCatalogId,
          name: '',
          commodityName: data.catalogName
        }
      })   
    },

    goShopListRight(data){
      this.$router.push({
        name: 'goodsList',
        query: {
          id: '',
          name: data,
          commodityName: data
        }
      })   
    },

    chooseCatalog(row){
      this.isCatalog = row.guideCatalogId;
      this.qa.queryUccCommodityPageListByGuideCatalogId({commodityOneLevelCategoryId: row.guideCatalogId, pageSize: 9999}).then(res => {
        
        this.goodsList = res.data.rows;
        console.log(this.goodsList)
      })
    },
    hideCatalog(){
      this.lmIndex = -1;
    },
    goList(){
      this.$router.push({
        name: 'goodsList',
        query: {
          name: this.searchContent,
          id: '',
          commodityName: this.searchContent,
        }
      })      
    },
    goDetail(row){
      this.$router.push({
        name: 'goodsDetail',
        query: {
          id: row.id
        }
      })
    },
    qryDic(){
        this.qa.queryUccGuideCatalogByList({}).then(res => {
            this.catalogList = this.buildTree(res.data.list);
            this.selectData = this.buildTree(res.data.list);
            this.chooseCatalog(this.catalogList[0]);
            // console.log(this.catalogList)
        })

        this.qa.queryUccCommodityPageListByGuideCatalogId({commodityOneLevelCategoryId: '', pageSize: 5}).then(res => {
            this.hotGoodsList = res.data.rows;
            
        })
    },
    buildTree(arr) {
        let temp = {}
        let tree = {}
        // 数组转 键值对
        arr.forEach(item => {
            temp[item.guideCatalogId] = item
        })

        let tempKeys = Object.keys(temp)
        tempKeys.forEach(key => {
            // 获取当前项
            let item = temp[key]
            // 当前项 pId
            let _itemPId = item.upperCatalogId
            // 获取父级项
            let parentItemByPid = temp[_itemPId]
            
            if (parentItemByPid) {
                if (!parentItemByPid.children) {
                    parentItemByPid.children = [];
                } else {
                    let count = 0;
                    for(let i = 0; i < parentItemByPid.children.length; i++){
                        if(item.guideCatalogId == parentItemByPid.children[i].guideCatalogId){
                            break;
                        }
                        count++;
                    }
                    if(count == parentItemByPid.children.length){
                        parentItemByPid.children.push(item)
                    }
                    
                }
                
            } else {
                tree[item.guideCatalogId] = item
                console.log(tree)
            }
        })
        // 对象转数组并返回
        return Object.keys(tree).map(key => tree[key])
        
    }
  }
}
</script>
<style scoped>
  .home>>>.el-carousel__item img {
    font-size: 18px;
    height: 500px;
    width: 716px;
    margin: 0;
  }
  .home>>>.el-carousel__container {
    width: 716px;
    height: 500px;
  }
  .home>>>.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }
  
  .home>>>.el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }
  .header {
    width: 100%;
    height: 126px;
    background: #fff;
  }
  .header-main {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
  }
  .header-logo {
    width: 60px;
    height: 60px;
  }
  .header-title {
    font-size: 28px;
    color: 333;
    font-weight: 600;
    margin: 0 37px 0 10px;
  }
  .header-search-box {
    width: 718px;
    height: 46px;
    display: flex;
    align-items: center;
    border: 1px solid #84C754;
    box-sizing: border-box;
    border-radius: 8px;
    padding-left: 21px;
  }
  .header-search {
    width: 570px;
    border: 0;
    
  }
  .header-search-btn {
    width: 106px;
    height: 44px;
    line-height: 44p[x];
    border: 0 8px 8px 0;
  }
  .sch_button {
    width: 106px;
    height: 46px;
    border: 0;
    margin-right: -1px;
    background: #84C754;
    font-weight: 800;
    color: #FFFFFF;
    border-radius: 0px 8px 8px 0px;
    cursor: pointer;
  }
  .codeJ {
    font-size: 14px;
    font-weight: 600;
    color: #999999;
    margin-bottom: 20px;
    
  }
</style>